<template>
    <div class="order">
      <div class="page-header">
        <h1>{{ $t('Service Order Management') }} > {{ $t('Order') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Order') }}</h2>
        </div>
        <OrderForm :editMode="true" @action="updateOrder"/>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import Common from '@/lib/common';
  import serviceOrder from '@/lib/serviceOrder';
  import OrderForm from '@/components/service/OrderForm.vue';
  
  export default {
    name: 'ServiceOrder',
    components: {
      OrderForm
    },
    mounted(){
      this.loadOrder();
    },
    methods:{
      async updateOrder(form){
        try{
          const loginInfo = Common.getLoginInfo();
          const orderId = this.$route.params.id;
          const order = await serviceOrder.updateOrder(this.apiUrl, orderId, form, loginInfo);
          this.$router.push('/services/orders');
        }catch(err){
          console.log(err);
        }
      },
      async loadOrder(){
        try{
          const loginInfo = Common.getLoginInfo();
          const orderId = this.$route.params.id;
          const order = await serviceOrder.loadOrder(this.apiUrl, orderId, loginInfo);
          this.setCurrentOrder(order);
        }catch(err){
          console.log(err);
        }
      },
      ...mapActions(['setCurrentOrder']),
    },
    computed: mapState({
     currentOrder: state => state.order.currentOrder,
     langcode: state => state.langcode,
     apiUrl: state => state.apiUrl,
   }),
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  </style>
  